.homeSlider-container{
  position: relative;
}
.homeSlider-container >div:first-child{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 999;
}
.homeSlider-container >div:first-child h1{
  color: white;
  /* font-size: 6rem; */
  /* font-size: 96px; */
  font-size: 6vw !important;
  font-weight: 700;
  /* line-height: 144px; */
}
.homeSlider-container >div:first-child p{
  color: white;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
  text-align: center;
}
.homeSlider-container >div:first-child button{
  color: white;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  border-radius: 39px;
  align-self: stretch;
}