.singlecategory-container .swiper {
  width: 100%;
  height: 100%;
}
.singlecategory-container .swiper-slide {
    margin-bottom: 50px;
    min-width: 250px;
}
.singlecategory-container .swiper-slide img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
.singlecategory-container .swiper-slide-active img {
    transform: scale(1.39); /* Adjust the scale factor as needed */
  }
.singlecategory-container .swiper-pagination-bullet {
    width: 15px; 
    height: 15px;
}
.singlecategory-container .swiper-pagination-bullet-active{
    transform: scale(1.5);
    background-color: var(--second-color);
}
.singlecategory-container h1{
    color: var(--main-color);
    text-align: left;
}
.singlecategory-container{
    padding: 30px;
}
.singlecategory-container >div{
    padding: 50px;
    margin: auto auto 50px auto;
    width: 95%;
}
.singlecategory-container p{
    color: #707070;
    font-size: 48px;
    font-weight: 400;
    line-height: 72px;
    text-align: left;
}