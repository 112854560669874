.relativeBlog-container{
    padding: 30px;
    width: 80%;
    margin: auto;
}
.relativeBlog-container >h1{
    color: var(--main-color);
}
.relativeBlog-container .cards{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
}
.relativeBlog-container .relativeBlog-card{
    width: calc(97%/3);
    /* min-width: 320px; */
}
.relativeBlog-container .relativeBlog-card img{
    width: 100%;
    border-radius: 33px;
}
.relativeBlog-container .relativeBlog-header h3{
    color: var(--main-color);
    text-decoration: underline;    
}
.relativeBlog-container h4{
    color: var(--second-color);
    text-decoration: underline;    
}
.relativeBlog-container .relativeBlog-btn button{
    background-color: transparent;
    color: var(--second-color) !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
}
.relativeBlog-container .relativeBlog-btn button:hover{
    background-color: transparent !important;
    color: var(--main-color) !important;
}