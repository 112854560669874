.landPage-container{
    height: 390px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: grayscale(50%);
}
.landPage-container h1{
    font-size: 64px;
    font-weight: 700;
    line-height: 96px;
    text-align: center;
}
.landPage-container p{
    text-align: center;
    font-size: 40px;
    font-weight: 400;
    line-height: 60px;
    
}