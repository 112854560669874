.productFilter-container{
    padding: 30px;
    background-color: var(--third-color);
    min-height: 234px;
    width: 85%;
    margin:50px auto auto auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}
.productFilter-container >div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(95%/3);
    min-width: 150px;
}
.productFilter-container >div h4{
    color: var(--main-color);
    /* font-size: 36px; */
    /* font-weight: 500; */
    /* line-height: 54px; */
    /* text-align: left;     */
}
.productFilter-container >div select{
    color: var(--third-color);
    height: 59px;
    width:100%;
    outline: 0;
    border: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    position: relative;
}
select::after{
    position: absolute;
    width: 10px;
    height: 10px;
    content: "dd";
    border: 10px red solid;
    top: 10px;
    left: 10px;
    /* background-color: red; */
}