.homeAbout-container{
    padding: 30px;
}
.homeAbout-container .homeAbout-content{
    display: flex;
    flex-wrap: wrap;
    gap: 50px 10px;
    justify-content: center;
}
.homeAbout-container .homeAbout-content .one { 
    width: 50%;
}
.homeAbout-container .homeAbout-content .one h1{
    color: var(--main-color);
}
.homeAbout-container .homeAbout-content .one h2{
    text-transform: lowercase !important;
    color: var(--fifth-color);
}
.homeAbout-container .homeAbout-content .one p{
    color: var(--main-color);
    font-size: 32px;
}
.homeAbout-container .homeAbout-content .one .btn button{
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 39px;
    color: white;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    margin-top: 10px;
    width: 70%;
}
.homeAbout-container .homeAbout-content .two{
    width: 35%;
}
.homeAbout-container .homeAbout-content .two img{
    width: 100%;
    border: 3px solid var(--main-color);
    border-radius: 59px;
    transform:rotate(0);
    transition-duration: var(--transition-duration);
    cursor: pointer;
}