:root{
    --main-color:#0F5996;
    --second-color:#8DC63F;
    --third-color:#D9D9D9;
    --fourth-color:#F1F9FF;
    --fifth-color:#707070;
    --six-color:#AFAFAF;
    --line-heigh:48px;
    --font-size:23px;
    --transition-duration: .3s;
    --main-border-radious:26px;
}