.footer-container{
    background-color: var(--main-color);
    color: white;
    padding: 50px 0;
    overflow: hidden;
}
.footer-container .footer-content{
    margin: auto;
    width: 90%;
    height: 90%;
    padding-bottom: 20px;
    border-bottom:3px solid var(--third-color) ;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.footer-container .footer-content >div{
    width: 23%;
    min-width: 300px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
}
.footer-container .footer-content div h1{
    text-transform: capitalize;
}
.footer-container .footer-content div p{
    color: var(--third-color);
    font-size: 32px;
    line-height: 48px;
}
.footer-container .footer-content div .footer-icon{
    display: flex;
    gap: 10px;
    font-size: xx-large;
}
.social-icon:hover{
    color: var(--second-color);
    transform: scale(1.2);
}
.footer-container .footer-content >div li,a{
    color: var(--third-color);
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    display: flex;
    /* flex-wrap: wrap; */
    gap: 10px;
    align-items: center;
}
.footer-container .copyRight{
    padding-top: 10px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}