.homeBlogs-container{
    padding: 30px;
}
.homeBlogs-container >h1{
    color: var(--main-color);
    text-align: center;
}
.homeBlogs-container >h2{
    color: var(--fifth-color);
    text-align: center;  
    margin-bottom: 30px;
}
.homeBlogs-container .blogCards{
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content:space-between;
    position: relative;
    margin-bottom: 50px;
}
.homeBlogs-container .homeBlog-card{
    width:calc(95%/3);
    display: flex;
    gap: 10px;
    flex-direction: column;
    overflow: hidden;
}
.homeBlogs-container .homeBlog-card img:hover .homeBlog-card{
    transform: translateY(10px);
    transform: scale(1.1);
}
.homeBlogs-container .blogCards .homeBlog-card .image{
    overflow: hidden;
    border-radius: 33px;
}
.homeBlogs-container .blogCards .homeBlog-card img{
    width:100%;
    height: 500px;
    border-radius: 33px;
    transition-duration: var(--transition-duration);
}
.homeBlogs-container .homeBlog-card img:hover{
    transform: scale(1.05);
}
.homeBlogs-container .blogCards .homeBlog-card .header h3{
    color: var(--main-color);
    text-decoration: underline;
}
.homeBlogs-container .blogCards .homeBlog-card .header h4{
    color: var(--second-color);
    text-decoration: underline;
}
.homeBlogs-container .blogCards .homeBlog-card >p{
    color: var(--main-color);
    font-size: 32px;
    text-align: left;
}