.homeContact-container{
    background-color: #F1F9FF;
    padding: 30px;
    position: relative;
    overflow: hidden;
}
.homeContact-container h1{
    color: var(--main-color);
    text-align: center;
}
.homeContact-container h2{
    color: var(--fifth-color);
    text-align: center;
}
.homeContact-container form{
    margin-top: 50px ;
    width: 50%;
    display: flex;
    gap: 20px;
    flex-direction: column;

}
.homeContact-container form input{
    background-color: white;
    color: var(--six-color);
    height: 115px;
    padding-left: 10px;
    border-radius: 26px;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
}
.homeContact-container form input::placeholder{
    text-transform: capitalize;
    color: var(--six-color);
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
}
.homeContact-container form button{
    color: white;
    font-size: 40px;
    font-weight: 700;
    border-radius: 26px;
    padding:9px 0px 9px 0px;
}
.homeContact-container div{
    margin-top: 50px;
}
.homeContact-container div img{
    opacity: .1;
    position: absolute;
    right: -150px;
    top: 50%;
    transform: translate(00% , -50%);
}
.homeContact-container .area{
    background-color: white;
    color: var(--six-color) ;
    height: 300px !important;
    width: 100%;
    flex: 1;
    padding-left: 10px;
    border-radius: 26px;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
    border: 0;
    outline: 0;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
}
.homeContact-container .area::placeholder{
    color: var(--six-color) ;

}