.blog-container{
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 50px;
}
.blog-container .blog-card{
    display: flex ;
    gap: 10px;
}
.blog-container .blog-card img{
    height: 486px;
    width: 486px;
    border-radius: 33px;
    cursor: pointer;
}
.blog-container .blog-card .blog-description{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
}
.blog-container .blog-card .blog-description .blog-header h2{
    color: var(--main-color);
    font-size: 40px;
    font-weight: 400;
    line-height: 60px;
    text-decoration: underline;    
}
.blog-container  .blog-card .blog-description .blog-header h3{
    color: var(--second-color);
    text-decoration: underline;    
}
.blog-container .blog-card .blog-description .blog-pragraph p{
    width: 70%;
    color: var(--main-color);
    font-size: 32px;
}