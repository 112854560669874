.homeCateg-container{
    background-color: var(--fourth-color);
    padding: 30px;
}
.homeCateg-container >h1{
    color: var(--main-color);
    text-align: center;
}
.homeCateg-container >h2{
    color: var(--fifth-color);
    text-align: center;  
    margin-bottom: 30px;
}
.homeCateg-container .cards{
    width: 90%;
    margin: auto;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    margin-bottom: 50px;
}
.homeCateg-container .cards .card{
    width:calc(90%/4);
    min-width: 250px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}




.homeCateg-container .cards .card .flippedCard{
    border-radius: 50%;
    width: 100%;
    position: relative;
    height: 250px;
    transition-duration: var(--transition-duration);
}
.flippedCard .firstCard{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.homeCateg-container .cards .card .firstCard img{
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
}
.flippedCard .secondCard{
    background-color: var(--main-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.flippedCard .secondCard h3{
    color: var(--fourth-color);
}



.flip{
    animation-name: flip;
    animation-duration: 1s;
}
.notFlip{
    animation-name: notFlip;
    animation-duration: 1s;
}
@keyframes flip {
    from{
    transform: rotateY(180deg);
    }
    to{
        transform: rotateY(0);
    }
}
@keyframes notFlip {
    from{
    transform: rotateY(180deg);
    }
    to{
        transform: rotateY(0);
    }
}





.homeCateg-container .cards .card div:nth-child(2){
    color: var(--main-color);
    text-align: center;
}
.homeCateg-container .cards .card p{
    color: var(--six-color);
    text-align: center;
}
.homeCateg-container .cards .card button{
    background-color: transparent ;
    color: var(--main-color);
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    padding: 16px  0;
    width:100%;
    border:2px solid var(--main-color) ;
    transition: var(--transition-duration);
}
.homeCateg-container .cards .card button:hover{
    background-color: var(--main-color) !important;
    color: white !important;
}


.homeCategOuter{
    position: absolute;
    bottom: -80px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
}
.homeCategOuter .empty-width{
    flex: 1;
    height: 2px;
    background-color: var(--main-color);
}
.homeCategOuter button{
    color:#FF0000;    ;
    background-color: transparent;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;    
}
.homeCategOuter button:hover{
    background-color: transparent !important;
}