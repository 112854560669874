/* start from 1200 to 1400 */
@media (max-width:1400px) {
    /* style slider in about page */
    .slider-container{
        width: 100% !important;
    }
    .slider-container .rightIcon{
        right:-38px !important;
    }
    .slider-container .leftIcon{
        left: 38px !important;
    }
}

/* start from 992  to 1200 */
@media (max-width:1200px) {
    /* style navbar page */
    .navbar-container .bars{
        display: block !important;
    }
    .navbar-container .nav-links{
        display: none;
    }
    .navbar-container .nav-btn{
        display: none;
    }
    /* style home blog page */
    .homeBlogs-container .homeBlog-card{
        width: 350px !important;
    }
    .homeBlogs-container .blogCards{
        width: 100% !important;
        justify-content: center !important;
    }
    /* style relative blog container */
    .relativeBlog-container .cards .relativeBlog-card{
        width: 320px !important;
    }
    /* 
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    */
    /* style blog page */
    .blogs-container{
        flex-wrap: wrap-reverse !important;
    }

}

/* start from 480 to 992 */
@media (max-width:992px) {
    /* style contact home page */
    .homeContact-container form{
        width: 100% !important;
    }
    /* style filter product page */
    .productFilter-container >div{
        width: calc(95%/2) !important;
    }
    /* style relative blog page */
    .relativeBlog-container{
        width: 100% !important;
    }
    /* style contact details section */
    .contactForm-container .inputOne{
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(1 , minmax(300px , 1fr)) !important;
    }
    .contactForm-container .inputTwo{
        grid-template-columns:repeat(1 , minmax(300px , 1fr)) !important;
    }
}

/* start from  480 to 768 */
@media (max-width:768px) {
    /* style home page slider */
    .homeSlider-container >div:first-child button{
        display: none;
    }
    .homeSlider-container >div:first-child h1{
        line-height: 42px !important;
    }
    /* style about home page */
    .homeAbout-container .homeAbout-content .one .btn button{
        width: 100% !important;
    }
    /* style category home page */
    .homeCateg-container .cards{
        justify-content: center !important;
    }
    /* style category page */
    .category-container .cards{
        justify-content: center !important;
    }
    /* style about slider image */
    .AboutSlide img{
        transform: scale(1) !important;
    }
    /* style filter product page */
    .productFilter-container >div{
        width: 90% !important;
    }
    /* style card in relative blog */
    .relativeBlog-container .cards{
        justify-content: center !important;
    }
    /* style contact page details */
    .details-container .details-img{
        width: 100% !important;
        height: 300px !important;
    }
    /* style blog page */
    .blogsFilter-container{
        width: 100% !important;
    }
    .blog-container{
        align-items: center !important;
        justify-content: center !important;
    }
    .blog-container .blog-card{
        width:100% !important;
        flex-direction: column !important;
        /* align-items: center !important; */
    }
    .blog-container .blog-card img{
        width: 100% !important;
    }
}

/* from 0 to 480px */
@media (max-width:480px) {
    /* style blog home page */
    .homeBlogs-container .homeBlog-card{
        width: 100% !important;
    }
    /* style single category */
    .singlecategory-container >div{
        padding: 50px 0!important;
    }
    /* style about page */
    .about-container{
        width: 100% !important;
    }
    /* style single blog page */
    .selectedBlog-container{
        width: 100% !important;
    }
    /* style contact details section */
    .details-container{
        width: 100% !important;
    }
    .contactForm-container >div{
        width: 100% !important;
    }
    .contactForm-container .inputOne{
        grid-template-columns: repeat(1 , minmax(250px , 1fr)) !important;
    }
    .contactForm-container .inputTwo{
        grid-template-columns:repeat(1 , minmax(250px , 1fr)) !important;
    }
}


/* custom media */
 @media (max-width:1081px) {
     /* style about home page */
    .homeAbout-container .homeAbout-content .two{
        width: 100% !important;
    }
    .homeAbout-container .homeAbout-content .one { 
        width: 100% !important;
    }
    /* style blog home page */
    .homeBlogs-container .blogCards .homeBlog-card{
        align-self: center !important;
    }
 }
 @media (max-width:1150px) {
     /* style contact page */
    .details-container .details-content{
        justify-content: center !important;
    }
    .details-container .details{
        width: 100% !important;
    }
 }
 @media (max-width:1150px) and (min-width:767px) {
     /* style single blog page */
     .details-container .details-img{
         width: 80% !important;
     }
 }
 @media (max-width:781px) and (min-width:480) {
     /* style single blog page */
    .details-container .details-img{
        width: 80% !important;
    }
}
@media (max-width:1200px) and (min-width:720px) {
    /* style blog page */
    .blogsFilter-container{
        width: 100% !important;
        height: 300px !important;
        display: flex !important;
    }
    .blogsFilter-container >div{
        width: 100% !important;
        display: flex;
        flex-direction: column;
        border-bottom: none !important;
        border-right: 2px solid var(--main-color) !important;
    }
    .blogsFilter-container .fourth{
        border: none !important;
    }
    .blogsFilter-container .first{
        display: flex !important;
        flex-direction: row !important;
        align-items: flex-start;
        justify-content: flex-start;
    }
}