.details-container{
    color: var(--main-color);
    width: 90%;
    padding: 30px;
    margin: auto;
}
.details-container .details-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.details-container .details-img {
    width: 50%;
    height: 400px;
}
.details-container .details-img img{
    width: 100%;
    height: 100%;
}
.details-container .details{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.details-container .details h2{
    color: var(--main-color);
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
}
.details-container .details h4{
    color: var(--main-color);
}
.details-container .details .detailsInfo{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.details-container .details .detailsInfo>div{
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--main-color);
}
.details-container .details .detailsInfo >div h1{
    color: var(--main-color);
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
}
.details-container .details .detailsInfo>div .detailsIcon{
    color: var(--second-color);
    font-size: xx-large;
}
.details-container .details .detailsIcon{
    display:flex;
    flex-wrap: wrap;
    gap: 40px;
    cursor: pointer;
}
.details-container .details .detailsIcon a{
    color: var(--second-color);
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
}
.details-container button{
    color: white;
    width: 100%;
    margin-top: 30px;
    border-radius: 39px;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
}