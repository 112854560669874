.selectedBlog-container{
    min-height: 100vh;
    width: 80%;
    padding: 30px;
    margin: auto;
    display: flex;
    gap: 50px;
    flex-direction: column;
}
.selectedBlog-container img{
    height: 450px;
    border-radius: 33px;
}
.selectedBlog-container .selectedBlog-header h3{
    color: var(--main-color);
    text-decoration: underline;    
}
.selectedBlog-container  .selectedBlog-header h4{
    color: var(--second-color);
    text-decoration: underline;    
}
.selectedBlog-container >p{
    color: var(--main-color);
    font-size: 32px;
}