@import url(../src/Components/Variable/Variable.css);
*{
    font-family: Arial, Helvetica, sans-serif ;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
button{
    cursor: pointer;
    text-transform: capitalize;
    background-color: var(--main-color);
    transition-duration:var(--transition-duration);
}
button:hover{
    background-color: var(--second-color) !important;
    color: var(--main-color) !important;
}
input , button{
    outline: 0;
    border: 0;
}
ul{
    list-style-type: none;
}
a{
    text-decoration: none;
}
h1,h2,h3,h4,h5{
    text-transform: capitalize;
}
p{
    font-weight: 400px;
    line-height: 48px;
}
h1{
    font-size: 64px;
    font-weight: 700;
    line-height: 96px;
}
h2{
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
}
h3{
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
}
h4{
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
}
.relative-links{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    color: var(--fifth-color);
    margin-bottom: 30px;
}
.relative-links button{
    background-color: transparent;
    color: var(--fifth-color);
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;

}
.relative-links button:hover{
    color: var(--main-color) !important;
    background-color: transparent !important;
}