.landPage-container .swiper {
    width: 100%;
    height:481px;
}
.landPage-container .swiper-slide {
  text-align: center;
  font-size: 18px;
}
.landPage-container .swiper-slide div{
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.landPage-container .swiper-slide img {
  width: calc(100%/4);
  min-width: 300px;
  height: 100%;
  border-radius: 0;
  object-fit: cover;
}
.landPage-container .swiper-button-prev,
.landPage-container .swiper-button-next{
  background-color: var(--main-color);
  color: white !important;
  width: 78px !important;
  height: 136px !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.landPage-container .swiper-button-next{
  right: 0 !important;
}
.landPage-container .swiper-button-prev{
  left: 0 !important;
}
  