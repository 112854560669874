.navbar-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: 32px solid var(--main-color);
    padding: 10px;
    position: relative;
}
.navbar-container .logo img{
    width: 178px;
    height: 68px;
}
.navbar-container ul{
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    gap: 20px;
}
.navbar-container ul li a{
    color: var(--main-color);
    text-decoration: none;
    text-transform: capitalize;
    font-size: var(--font-size);
    font-weight: 500;
    line-height: 42px;
}
.navbar-container ul li a.active{
    color: var(--second-color);
}
.navbar-container div button{
    background-color: var(--main-color);
    color: white;
    padding: 5px 40px;
    border-radius: 39px;
    font-size: var(--font-size);
    font-weight: 700;
    line-height: 36px;
}
.navbar-container .bars{
    display: none;
    font-size: 48px;
    color: var(--main-color);
}
.navbar-container .sideBar{
    padding: 30px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 999999999999999;
    animation-name: sideBar;
    animation-duration: var(--transition-duration);
    overflow: hidden;
}
@keyframes sideBar {
    from{
        /* margin-left: -1000px; */
        transform: translateX(1000px);

    }
    to{
        /* margin-left: 0; */
        transform: translateX(0);

    }
}