.category-container{
    min-height: 100vh;
    width: 90%;
    padding: 30px;
    margin: auto;
}
.category-container .cards{
    display: flex;
    gap: 50px 20px;
    justify-content: space-between;
    flex-wrap: wrap;
}
.category-container .category-card{
    width: calc(90%/4);
    min-width: 250px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.category-container .category-card img{
    width: 100%;
    border-radius: 50%;
}
.category-container .category-card h3{
    color: var(--main-color);
    text-align: center;
}
.category-container .category-card p{
    color: var(--six-color);
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}
.category-container .category-card button{
    color: var(--main-color);
    background-color: white !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    width: 100%;
    border: 2px solid var(--main-color);
    padding: 16px 0;
    transition-duration: var(--transition-duration);
}
.category-container .category-card button:hover{
    background-color: var(--main-color) !important;
    color: white !important;
}