.greenBtn-container div.btn{
    display: flex;
    align-self: flex-start;
}
.greenBtn-container button{
    color: var(--second-color);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;    
    background-color:transparent;
    transition: var(--transition-duration);
}
.greenBtn-container button:hover{
    background-color:transparent !important;
}