.contactForm-container{
    padding: 30px;
    overflow: hidden;
}
.contactForm-container >div{
    width: 90%;
    margin: auto;
}
.contactForm-container >div h1{
    color: var(--main-color);
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    
}
.contactForm-container >div form{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
}
.contactForm-container .inputOne{
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2 , minmax(200px , 1fr));
}
.contactForm-container .inputTwo{
    display: grid;
    gap: 10px;
    /* this line make a space */
    /* grid-template-columns:repeat(auto-fit , minmax(300px , 1fr)); */
    grid-template-columns: repeat(3 , minmax(200px , 1fr));
}
.contactForm-container .inputOne input ,.inputTwo input{
    background-color: #F4F4F4;
    height: 115px;
    padding-left: 10px;
    border-radius: 26px;
    color: #707070;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
}
.contactForm-container >div form button{
    color: white;
    font-size: 40px;
    font-weight: 700;
    border-radius: 26px;
    padding:9px 0px 9px 0px;
    width: 100%;
}
.contactForm-container >div input::placeholder{
    text-transform: capitalize;
    color: #707070;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
}
.area{
    background-color: #F4F4F4;
    color: #707070;
    height: 250px !important;
    width: 100%;
    flex: 1;
    padding-left: 10px;
    border-radius: 26px;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
    border: 0;
    outline: 0;
}
.checkbox{
    display: flex;
    align-items: center;
    justify-content: center;      
}
.contactForm-container input[type="checkbox"] {
    width: 3em;
    height: 3rem;
    accent-color: var(--second-color);
}
.checkbox{
    display: flex;
    gap: 10px;
    justify-content: flex-start;
}
.checkbox p{
    color: #707070;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
}
