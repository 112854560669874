.market-container{
    min-height: 100vh;
    padding: 30px;
}
.product-cards{
    margin: auto;
    display: flex;
    gap:50px 30px;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5%;
}
.product-cards .card{
    background: #D9D9D9;
    width:calc(75%/3);
    min-width: 300px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    border-radius: 43px;
}
.product-cards .card .content{
    background-color: #E7E7E7;
    box-sizing: border-box;
    padding:10px 30px;
    border-radius: 43px;
    width: 100%;
}
.product-cards .card h3{
    text-align: left;
    color: var(--main-color);
}
.product-cards .card p{
    text-align: left;
    color: var(--main-color);
    font-size: 24px;
}
