.slider-container{
    width: 90%;
    margin: auto;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
}
.slider-container .swiper-slide {
    margin: auto;
    width:800px !important;
    height: 600px !important;   
    display:flex;
}
.AboutSlide img {
    margin: auto;
    background-color: red;
    width: 350px !important;
    height: 350px !important;
    transform: scale(1.8);
    border-radius: 33px !important;
    transition-duration: var(--transition-duration);
}
.slider-container  .rightIcon,.slider-container .leftIcon{
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.slider-container .rightIcon , .slider-container .leftIcon{
    background-color: var(--main-color);
    width:78px ;
    height: 136px;
    z-index: 99;
}
.slider-container .rightIcon{
    right: 0;
}
.slider-container .leftIcon{
    left: 75px;
}
.angleRight ,.angleLeft{
    font-size: 50px;
    font-weight: 200;
    color: white;
    background-color: transparent;
}





/* ------------------ */

.swiper-slide-active{
    /* background-color: red; */
    z-index: -9999999 !important;
    /* border-radius: 0 !important; */
    /* display: none !important; */
}