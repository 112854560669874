.market-container .market-tabs{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.market-container .tab{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.market-container .tab img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px #00000040;
    transition-duration: var(--transition-duration);
    cursor: pointer;
    object-fit: cover;
}
.market-container .market-tabs .tab h1{
    color: var(--main-color);
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    
}
.market-container .tab img:hover{
    transform: scale(1.2);
}