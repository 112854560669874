.blogsFilter-container{
    width: 40%;
    height:800px;
    border-radius: 104px;
    border: 2px solid var(--main-color);
}
.blogsFilter-container >div{
    padding: 30px;
    border-bottom: 2px solid var(--main-color);
}
.blogsFilter-container h4{
    color: var(--main-color);
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
}
.blogsFilter-container label{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;  
    color: var(--six-color);
}
.blogsFilter-container .fourth{
    border: none;
}
.blogsFilter-container .first{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.blogsFilter-container .first .iconFilter{
    color: var(--main-color);
    font-size: xx-large;
}
.blogsFilter-container .second select{
    background-color: transparent;
    color: var(--six-color);
    width: 80% ;
    height: 50px;
    border-radius: 19px;
    border: 1px solid #0F5996;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;

}
.blogsFilter-container .third >div
,.blogsFilter-container .fourth >div{
    display: flex;
    gap: 5px;
}